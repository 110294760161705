import * as React from "react";
import { linkBulletCss } from "./LinkBullet.css";

export function LinkBullet() {
  return (
    <svg
      className={linkBulletCss}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35 15"
    >
      <path stroke="currentColor" d="M0 10h30" />
      <circle cx="30" cy="10" r="5" />
    </svg>
  );
}
