/* global gtag */
import * as React from "react";
import { graphql } from "gatsby";
import {
  MastHead,
  Headline,
  BackgroundImage,
} from "../modules/MastHead/index.ts";
import { Button } from "../components/Button.tsx";
import { Container } from "../components/Container.tsx";
import { Link } from "../components/UniversalLink.tsx";
import { Row } from "../modules/Grid/Row.tsx";
import { MastheadBlock } from "../modules/MastheadBlock";
import { Teaser } from "../modules/TeaserBlock/Teaser";
import { useTitle, useMeta } from "hoofd";
import { serviceBoxContainerCss, buttonCss } from "./index.css";
import DefaultLayout from "../layout/default";
import { sectionCss } from "../atomics.css";

// markup
const IndexPage = ({ data: { contentfulPage } }) => {
  useTitle(contentfulPage.pageTitle || contentfulPage.title);
  useMeta({ name: "description", content: contentfulPage.description });

  return (
    <DefaultLayout>
      <MastHead dark={false}>
        <Headline
          title={
            <>
              Goossens Accountancy,
              <wbr />
              proactief en toekomstgericht.
            </>
          }
          subtext={
            <>
              Vanuit een blik op de toekomst begeleiden en <wbr />
              adviseren wij zowel starters als ervaren ondernemers.
            </>
          }
        />
        <Button
          as={Link}
          to="/contact/"
          variant="inverse"
          className={buttonCss}
          onClick={() => {
            gtag("event", "click", {
              event_category: "general",
              event_label: "contact",
            });
          }}
        >
          Contacteer ons
        </Button>
        <BackgroundImage
          image={
            contentfulPage.titleBackground?.localFile?.childImageSharp
              ?.gatsbyImageData
          }
          alt=""
          role="presentation"
          dark={false}
        />
      </MastHead>

      {contentfulPage.contentBlocks.map((node, index) => {
        if (node.__typename === "ContentfulMastheadBlock") {
          return (
            <section className={sectionCss} key={node.id}>
              <Container>
                <MastheadBlock {...node} />
              </Container>
            </section>
          );
        }

        if (node.__typename === "ContentfulTeaserBlock") {
          return (
            <section
              className={sectionCss}
              style={{ background: "#c8d2e1" }}
              key={node.id}
            >
              <div className={serviceBoxContainerCss}>
                <Row>
                  {node.teasers.map((teaser, index) => {
                    return (
                      <Teaser
                        {...teaser}
                        theme={index % 2 === 0 ? "light" : "dark"}
                        key={teaser.id}
                      />
                    );
                  })}
                </Row>
              </div>
            </section>
          );
        }

        return null;
      })}

      {/* <section className={sectionCss}>
        <Container>
          <Row>
            <div>
              <Heading as="h2" size="small">
                Nieuwsbrief
              </Heading>

              <p>Blijf op de hoogte, schrijf je hier in op onze nieuwsbrief.</p>

              <Button>Inschrijven</Button>
            </div>
            <div>
              <Heading as="h2" size="small">
                Blog
              </Heading>

              <div>
                <div>Titel</div>
                <div>Titel</div>
              </div>
            </div>
          </Row>
        </Container>
      </section> */}
    </DefaultLayout>
  );
};

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "homepage" }) {
      title
      strapline
      titleBackground {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, height: 500)
          }
        }
      }
      pageTitle
      description
      contentBlocks {
        __typename
        ... on ContentBlock {
          id
        }
        ... on ContentfulMastheadBlock {
          title
          strapline
          description
          cta
          image {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 550
                  placeholder: TRACED_SVG
                  quality: 80
                )
              }
            }
          }
        }

        ... on ContentfulTeaserBlock {
          id
          teasers {
            title
            id
            ctaLink
            cta
            description
          }
        }
      }
    }
  }
`;

export default IndexPage;
