import * as React from "react";
import { Button } from "../../components/Button.tsx";
import { Heading } from "../../components/Heading.tsx";
import { Link } from "../../components/UniversalLink.tsx";
import { Row } from "../Grid/Row.tsx";
import { BoxParagraph } from "../../components/BoxParagraph";
import { Spacer } from "../../components/Spacer";
import { headlineInsetCss } from "./index.css";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

export const MastheadBlock: React.FC<{
  title: string;
  description: string;
  cta: string;
  image: {
    title: string;
    localFile: {
      childImageSharp: { gatsbyImageData: IGatsbyImageData };
    };
  };
}> = function MastheadBlock({ title, description, cta, image }) {
  return (
    <Row>
      <div>
        <div className={headlineInsetCss}>
          <Heading as="h2">{title}</Heading>
          <Spacer size="extraLarge" />
          <BoxParagraph>{description}</BoxParagraph>
          <Spacer size="extraLarge" />
          <Spacer size="extraLarge" />
          <Button as={Link} to="/over-ons/">
            {cta}
          </Button>
        </div>
      </div>
      <div>
        <Spacer size={["extraLarge", null]} />
        <GatsbyImage
          image={image.localFile.childImageSharp.gatsbyImageData}
          alt={image.title}
        />
      </div>
    </Row>
  );
};
