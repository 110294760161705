import * as React from "react";
import classNames from "clsx";
import { paragraphCss } from "./Text.css";

export const Text: React.FC<{
  as?: React.ElementType;
  className?: string;
}> = function Text({ as: Component = "p", className, ...props }) {
  return (
    <Component className={classNames(paragraphCss, className)} {...props} />
  );
};
