import * as React from "react";
import { Heading } from "../../components/Heading.tsx";
import { LinkBullet } from "../../components/LinkBullet.tsx";
import { Link } from "../../components/UniversalLink.tsx";
import { Spacer } from "../../components/Spacer";
import { Text } from "../../components/Text";
import {
  serviceBoxLightCss,
  serviceBoxDarkCss,
  serviceLinkCss,
} from "./Teaser.css";

export interface ITeaser {
  title: string;
  description: string;
  cta: string;
  ctaLink: string;
  theme: "light" | "dark";
}

export const Teaser: React.FC<ITeaser> = function Teaser({
  title,
  description,
  cta,
  ctaLink,
  theme,
}) {
  return (
    <div className={theme === "light" ? serviceBoxLightCss : serviceBoxDarkCss}>
      <Heading as="h2" size="small">
        {title}
      </Heading>
      <Spacer size="extraLarge" />
      <Spacer size="extraLarge" />
      <Text>{description}</Text>
      <Spacer size="medium" />
      <Spacer size="extraLarge" />

      <Link to={ctaLink} className={serviceLinkCss}>
        <LinkBullet />
        {cta}
      </Link>
    </div>
  );
};
